import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import saveAs from 'file-saver';
import { IFeedbacksHistoryComponent, IGroupedHistory } from './types';
import { useApi } from '../../../hooks/useApi';
import { postFileRequest, postRequest } from '../../../api';
import { downloadFeedbackFileUrl, feedbackHistoryUrl } from '../../../constants/api';
import {
  EFeedbackPriority,
  EFeedbackStatus,
  EHistoryStatus,
  IHistoryFeedback,
  IMediaData,
  mapFeedbackPriorityToText,
  mapFeedbackStatusToText,
  mapHistoryStatusToText,
} from '../../../typings/feedback';
import { dateDefaultFormat } from '../../../constants/date';
import FeedbacksScore from '../feedbackScore';
import Select from '../../ui/select';
import { ISelectOption } from '../../ui/select/types';
import Button from '../../ui/button';
import { ButtonType } from '../../ui/button/types';
import HistoryCommentModal from '../historyCommentModal';
import SimpleGallery from '../../simpleGallery';

const FeedbacksHistory: FC<IFeedbacksHistoryComponent> = (props) => {
  const { appealId, feedbackData } = props;

  const { data: history, sendRequest: getHistory } = useApi<IHistoryFeedback[]>(postRequest);

  const [operationType, setOperationType] = useState<string>('');
  const [userRole, setUserRole] = useState<string>('');

  const [commentModalOpen, setCommentModalOpen] = useState(false);

  const { sendRequest: requestImage, loading: downloadImageLoading } = useApi(postFileRequest);

  const requestHistory = useCallback(
    (type: string, role: string) => {
      if (appealId) {
        getHistory(feedbackHistoryUrl(appealId), {}, { params: { type, tag: role } });
      }
    },
    [appealId, getHistory]
  );

  useEffect(() => {
    requestHistory('', '');
  }, [appealId]);

  const changeType = useCallback(
    (val: string | number) => {
      setOperationType(val?.toString());
      requestHistory(val?.toString(), userRole);
    },
    [requestHistory, userRole]
  );

  const changeRole = useCallback(
    (val: string | number) => {
      setUserRole(val?.toString());
      requestHistory(operationType, val?.toString());
    },
    [operationType, requestHistory]
  );

  const onSendMessage = useCallback(() => {
    setCommentModalOpen(false);
    requestHistory('', '');
  }, [requestHistory]);

  const onDownloadImage = useCallback(
    (partId: string) => async (item: IMediaData) => {
      const result = await requestImage(downloadFeedbackFileUrl(feedbackData?.id || '', partId), {
        params: {
          fileId: item.id,
        },
      });
      if (result.data) {
        saveAs(result.data, item.fileName);
      }
    },
    [requestImage, feedbackData?.id]
  );

  const getActionByType = useCallback((item: IHistoryFeedback) => {
    let action = null;
    switch (item.type) {
      case EHistoryStatus.changeAssignedUser: {
        action = (
          <>
            назначил(а) исполнителя <strong>{item.after}</strong>
          </>
        );
        break;
      }
      case EHistoryStatus.comment: {
        action = <>оставил внутренний комментарий:</>;
        break;
      }
      case EHistoryStatus.changeStatus: {
        action = item.before ? (
          <>
            сменил(а) статус с <strong>«{mapFeedbackStatusToText.get(item.before as EFeedbackStatus)}»</strong> на{' '}
            <strong>«{mapFeedbackStatusToText.get(item.after as EFeedbackStatus)}»</strong>
          </>
        ) : (
          <>создал(а) обращение</>
        );
        break;
      }
      case EHistoryStatus.changePriority: {
        action = (
          <>
            сменил(а) приоритет с <strong>«{mapFeedbackPriorityToText.get(item.before as EFeedbackPriority)}»</strong>{' '}
            на <strong>«{mapFeedbackPriorityToText.get(item.after as EFeedbackPriority)}»</strong>
          </>
        );
        break;
      }
      case EHistoryStatus.changeCategory: {
        action = (
          <>
            сменил(а) категорию с <strong>«{item.before}»</strong> на <strong>«{item.after}»</strong>
          </>
        );
        break;
      }
      case EHistoryStatus.changeSubject: {
        action = (
          <>
            сменил(а) тему обращения с <strong>«{item.before}»</strong> на <strong>«{item.after}»</strong>
          </>
        );
        break;
      }
      case EHistoryStatus.setScore: {
        action = <>оценил(а) работу</>;
        break;
      }
      default:
        break;
    }
    return action;
  }, []);

  const renderMessage = useCallback(
    (data: IGroupedHistory) =>
      data.events.map((arr, index) => {
        let isSentToUser = false;
        for (const item of arr) {
          if (item.after === EFeedbackStatus.done || item.after === EFeedbackStatus.cancelled) {
            isSentToUser = true;
            break;
          }
        }
        return (
          <div
            key={`history-group-item-${index}`}
            className={classNames('feedback-history__event', {
              'feedback-history__event_user': isSentToUser,
            })}
          >
            {isSentToUser && <div className="feedback-history__event-title">Отправлено абоненту:</div>}
            <div className="feedback-history__event-body">
              <div className="feedback-history__event-tag">{index === 0 ? data.tag : ''}</div>
              <div className="feedback-history__event-content">
                {arr.map((item) => (
                  <>
                    <div className="feedback-history__event-message">
                      {item.userFIO} ({item.userEmail}) {getActionByType(item)}
                    </div>
                    {item.score && item.type === EHistoryStatus.setScore && (
                      <FeedbacksScore wrapClassName="feedback-history__score" score={item.score} />
                    )}
                    {item.comment && <div className="feedback-history__event-comment">{item.comment}</div>}
                    {!!item.mediaUrls.length && (
                      <SimpleGallery
                        showDownloadAll={false}
                        loading={downloadImageLoading}
                        onDownloadImage={onDownloadImage(item?.feedbackPartId || '')}
                        data={item.mediaUrls}
                      />
                    )}
                  </>
                ))}
                <div className="feedback-history__event-date">
                  {moment(arr[0].createTime || '').format(dateDefaultFormat)}
                </div>
              </div>
            </div>
          </div>
        );
      }),
    [downloadImageLoading, getActionByType, onDownloadImage]
  );

  const typeOptions = useMemo((): ISelectOption[] => {
    const result: ISelectOption[] = [];
    mapHistoryStatusToText.forEach((item, key) =>
      result.push({
        value: key,
        title: item,
      })
    );
    return result;
  }, []);

  const roleOptions = useMemo(
    (): ISelectOption[] => [
      {
        value: 'Исполнитель',
        title: 'Исполнитель',
      },
      {
        value: 'Оператор',
        title: 'Оператор',
      },
      {
        value: 'Абонент',
        title: 'Абонент',
      },
    ],
    []
  );

  const groupedHistory: IGroupedHistory[] = useMemo(() => {
    const result: IGroupedHistory[] = [];
    let curTag = '';

    if (history) {
      history.forEach((item) => {
        if (curTag !== item.tag) {
          curTag = item.tag;
          result.push({
            tag: curTag,
            events: [[item]],
          });
        } else {
          const events = result[result.length - 1].events || [];
          let isFind = false;
          for (const arr of events) {
            if (isFind) {
              break;
            }
            for (const elem of arr) {
              if (item.createTime === elem.createTime) {
                isFind = true;
                arr.push(item);
                break;
              }
            }
          }
          if (!isFind) {
            events.push([item]);
          }
        }
      }, []);
    }

    return result;
  }, [history]);

  return (
    <div className="feedback-history">
      <HistoryCommentModal
        isOpen={commentModalOpen}
        onCancel={() => setCommentModalOpen(false)}
        onOk={onSendMessage}
        feedbackId={feedbackData?.id}
      />
      {history?.length || operationType || userRole ? (
        <div className="feedback-history__content">
          <div className="feedback-history__content-filters">
            <Select
              isAllOption
              title="Тип операции"
              value={operationType}
              onChange={changeType}
              options={typeOptions}
            />
            <Select isAllOption title="Роль участника" value={userRole} onChange={changeRole} options={roleOptions} />
            {!(feedbackData?.status === EFeedbackStatus.done) && (
              <Button type={ButtonType.outline} onClick={() => setCommentModalOpen(true)}>
                Добавить комментарий
              </Button>
            )}
          </div>
          {groupedHistory?.map((item) => renderMessage(item))}
        </div>
      ) : (
        <div className="feedback-history__empty-text">Пока нет ни одной записи</div>
      )}
    </div>
  );
};

export default FeedbacksHistory;
