import React, { FC, useCallback, useEffect, useRef } from 'react';
import { ISimpleGallery } from './types';
import DownloadIcon from '../../assets/svg/icons/download';
import ChevronLeft from '../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../assets/svg/icons/chevronRight';
import DownloadBoldIcon from '../../assets/svg/icons/downloadBold';
import { ELoaderColor } from '../ui/loader/types';
import Loader from '../ui/loader';
import WhiteImgIcon from '../../assets/svg/icons/whiteImg';

const SimpleGallery: FC<ISimpleGallery> = (props) => {
  const {
    loading = false,
    data = [],
    wrapperClassName = '',
    onDownloadImage = () => {},
    onDownloadAllImages = () => {},
    showDownloadAll = true,
  } = props;

  const imgRef = useRef<HTMLDivElement | null>(null);
  const scrollElementRef = useRef<HTMLDivElement | null>(null);

  const chevronLeftRef = useRef<HTMLDivElement | null>(null);
  const chevronLefIsHidden = useRef<boolean>(true);
  const chevronRightRef = useRef<HTMLDivElement | null>(null);
  const chevronRightIsHidden = useRef<boolean>(true);

  const showButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && ref.current) {
      element.classList.remove('tabs__tabs-button_hide');
      ref.current = false;
    }
  }, []);

  const hideButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && !ref.current) {
      element.classList.add('tabs__tabs-button_hide');
      ref.current = true;
    }
  }, []);

  const showOrHideButtons = useCallback(
    (scroll = scrollElementRef.current) => {
      if (scroll && imgRef.current) {
        if (imgRef.current.offsetWidth < scroll.scrollWidth) {
          showButton(chevronRightRef.current, chevronRightIsHidden);
          hideButton(chevronLeftRef.current, chevronLefIsHidden);
        } else {
          hideButton(chevronRightRef.current, chevronRightIsHidden);
          hideButton(chevronLeftRef.current, chevronLefIsHidden);
        }
      }
    },
    [hideButton, showButton]
  );

  const scrollEvent = useCallback(() => {
    const value = scrollElementRef.current?.scrollLeft || 0;
    const maxValue = scrollElementRef.current?.scrollWidth || 0;
    const width = scrollElementRef.current?.offsetWidth || 0;
    if (value > 50) {
      showButton(chevronLeftRef.current, chevronLefIsHidden);
    } else if (value < 50) {
      hideButton(chevronLeftRef.current, chevronLefIsHidden);
    }
    if (value + width >= maxValue - 20) {
      hideButton(chevronRightRef.current, chevronRightIsHidden);
    } else {
      showButton(chevronRightRef.current, chevronRightIsHidden);
    }
  }, [hideButton, showButton]);

  useEffect(() => {
    if (!scrollElementRef.current && imgRef.current) {
      scrollElementRef.current = imgRef.current;
      showOrHideButtons(scrollElementRef.current);
      scrollElementRef.current.onscroll = scrollEvent;
    }
  }, [imgRef, imgRef.current]);

  useEffect(() => {
    showOrHideButtons();
  }, [data]);

  const scrollTo = useCallback(
    (toRight = true) =>
      () => {
        if (scrollElementRef.current) {
          scrollElementRef.current.scrollBy({ left: toRight ? 400 : -400, behavior: 'smooth' });
        }
      },
    []
  );

  return data.length === 0 ? null : (
    <div className={`simple-gallery ${wrapperClassName}`}>
      {loading && (
        <div className="simple-gallery__loader">
          <Loader color={ELoaderColor.blue} />
        </div>
      )}
      <div className="simple-gallery__count">Файлов: {data.length}</div>

      <div
        ref={chevronLeftRef}
        role="presentation"
        onClick={scrollTo(false)}
        className="tabs__tabs-button tabs__tabs-button_left tabs__tabs-button_hide"
      >
        <ChevronLeft />
      </div>
      <div
        ref={chevronRightRef}
        role="presentation"
        onClick={scrollTo()}
        className="tabs__tabs-button tabs__tabs-button_right tabs__tabs-button_hide"
      >
        <ChevronRight />
      </div>
      <div ref={imgRef} className="simple-gallery__container default-scrollbar-override">
        {data.map((item, index) => (
          <div key={`$photo-${index}`} className="simple-gallery__item">
            <div className="simple-gallery__item-shadow" />
            <div className="simple-gallery__item-name">{item.fileName}</div>
            <div className="simple-gallery__item-img-icon">
              <WhiteImgIcon />
            </div>
            <div className="simple-gallery__item-size">{(item.length / 1024 / 1024).toFixed(2)} МБ</div>
            <div
              role="presentation"
              onClick={() => onDownloadImage(item)}
              className="simple-gallery__item-download-icon"
            >
              <DownloadBoldIcon />
            </div>
            {['mp4'].includes(item.extension) ? (
              <video>
                <source src={item.url} type="video/mp4" />
              </video>
            ) : (
              <img alt={item.fileName} src={item.url} />
            )}
          </div>
        ))}
      </div>

      {data.length > 1 && showDownloadAll && (
        <div className="simple-gallery__download-button" role="presentation" onClick={onDownloadAllImages}>
          Скачать все
          <div className="simple-gallery__download-button-icon">
            <DownloadIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default SimpleGallery;
