import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { useApi } from '../../../hooks/useApi';
import { getRequest, postFileRequest, postRequest } from '../../../api';
import { setHeaderTitle } from '../../../store/slices/header';
import CommonHead from '../../../components/commonHead';
import { paths } from '../../../constants/paths';
import { downloadFeedbackFileUrl, feedbackAssignToMeUrl, feedbackDataUrl } from '../../../constants/api';
import ButtonLink from '../../../components/ui/buttonLink';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import Loader from '../../../components/ui/loader';
import { ELoaderColor } from '../../../components/ui/loader/types';
import { IProfilePermission } from '../../../typings/profile';
import InformationBlock from '../../../components/ui/informationBlock';
import { dateDefaultFormat, dateFormatNoYear } from '../../../constants/date';
import Button from '../../../components/ui/button';
import { ButtonType } from '../../../components/ui/button/types';
import {
  EFeedbackPriority,
  EFeedbackStatus,
  IFeedbackFullData,
  IFeedbackPart,
  IMediaData,
} from '../../../typings/feedback';
import ExpandableBlock from '../../../components/ui/expandableBlock';
import SimpleGallery from '../../../components/simpleGallery';
import FeedbacksPriority from '../../../components/feedback/feedbackPriority';
import FeedbackStatus from '../../../components/feedback/feedbackStatus';
import EditableBlock from '../../../components/ui/editableBlock';
import ChangePriorityModal from '../../../components/feedback/changePriorityModal';
import ChangeCategoryModal from '../../../components/feedback/changeCategoryModal';
import ChangeStatusModal from '../../../components/feedback/changeStatusModal';
import ChangeAssignModal from '../../../components/feedback/changeAssignModal';
import Tabs from '../../../components/tabs';
import { ITab } from '../../../components/tabs/types';
import FeedbacksHistory from '../../../components/feedback/feedbackHistory';
import FeedbacksScore from '../../../components/feedback/feedbackScore';

const FeedbackPage: FC = () => {
  const params = useParams();

  const navigate = useNavigate();

  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);

  const [priorityModalIsOpen, setPriorityModalIsOpen] = useState(false);

  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);

  const [assignModalIsOpen, setAssignModalIsOpen] = useState(false);

  const dispatch = useAppDispatch();

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.feedback)) as IProfilePermission;
  const subscriberPermissions = useAppSelector(getProfilePermission(ESidebarItemIds.subscribers));

  const {
    data: feedbackData,
    sendRequest: getFeedbackData,
    loading: feedbackDataLoading,
  } = useApi<IFeedbackFullData>(getRequest);

  const { sendRequest: requestImage, loading: downloadImageLoading } = useApi(postFileRequest);

  const { sendRequest: assignToMe, loading: assignToMeLoading } = useApi(postRequest);

  const [activeTab, setActiveTab] = useState<string>('');

  const getData = useCallback(async () => {
    await getFeedbackData(feedbackDataUrl(params.feedbackId || ''));
  }, [getFeedbackData, params.feedbackId]);

  useEffect(() => {
    getData();
  }, [params.feedbackId]);

  const feedbackTitle = useMemo(() => `Обращение N°${feedbackData?.displayId}`, [feedbackData?.displayId]);

  useEffect(() => {
    if (feedbackData) {
      dispatch(setHeaderTitle(feedbackTitle));
      setActiveTab(feedbackData.parts[feedbackData.parts.length - 1].id);
    }
  }, [feedbackData]);

  const onUpdateData = useCallback(
    (setter: (val: boolean) => void) => () => {
      setter(false);
      getData();
    },
    [getData]
  );

  const onAssignYourself = useCallback(async () => {
    await assignToMe(feedbackAssignToMeUrl(params.feedbackId || ''), {});
    getData();
  }, [assignToMe, getData, params.feedbackId]);

  const onDownloadImage = useCallback(
    (partId: string) => async (item: IMediaData) => {
      const result = await requestImage(downloadFeedbackFileUrl(feedbackData?.id || '', partId), {
        params: {
          fileId: item.id,
        },
      });
      if (result.data) {
        saveAs(result.data, item.fileName);
      }
    },
    [requestImage, feedbackData?.id]
  );

  const onDownloadAllImages = useCallback(
    (partId: string) => async () => {
      const result = await requestImage(downloadFeedbackFileUrl(feedbackData?.id || '', partId), {});
      saveAs(result.data, 'Feedback.zip');
    },
    [feedbackData?.id, requestImage]
  );

  const isReopened = useMemo(() => (feedbackData?.parts?.length || 0) > 1, [feedbackData?.parts?.length]);

  const withoutSubscriber = useMemo(() => !feedbackData?.subscriberId, [feedbackData?.subscriberId]);

  const renderMainInfo = useCallback(
    (data: IFeedbackPart) => (
      <>
        <div className="feedback-page__text">{data?.text}</div>
        <SimpleGallery
          loading={downloadImageLoading}
          onDownloadAllImages={onDownloadAllImages(data?.id || '')}
          onDownloadImage={onDownloadImage(data?.id || '')}
          data={data?.mediaUrls}
        />
      </>
    ),
    [downloadImageLoading, onDownloadAllImages, onDownloadImage]
  );

  const disableEdit = useMemo(
    () =>
      !permissions?.edit ||
      feedbackData?.status === EFeedbackStatus.done ||
      feedbackData?.status === EFeedbackStatus.cancelled,
    [feedbackData?.status, permissions?.edit]
  );

  if (feedbackDataLoading) {
    return (
      <div className="feedback-page__loader-wrapper">
        <Loader color={ELoaderColor.blue} />
      </div>
    );
  }

  return feedbackData ? (
    <>
      <CommonHead seo={{ title: feedbackTitle }} />

      <ChangePriorityModal
        feedbackId={params.feedbackId}
        onCancel={() => setPriorityModalIsOpen(false)}
        defaultPriority={feedbackData.priority}
        onOk={onUpdateData(setPriorityModalIsOpen)}
        isOpen={priorityModalIsOpen}
      />

      <ChangeCategoryModal
        defaultCategory={feedbackData.categoryId}
        defaultSubject={feedbackData.subjectId}
        feedbackId={params.feedbackId}
        onCancel={() => setCategoryModalIsOpen(false)}
        onOk={onUpdateData(setCategoryModalIsOpen)}
        isOpen={categoryModalIsOpen}
      />

      <ChangeStatusModal
        defaultStatus={feedbackData.status}
        assignedUserEmailDefault={feedbackData.assignedUserEmail}
        assignedUserFioDefault={feedbackData.assignedUserFio}
        assignedUserIdDefault={feedbackData.assignedUserId}
        feedbackId={params.feedbackId}
        onCancel={() => setStatusModalIsOpen(false)}
        onOk={onUpdateData(setStatusModalIsOpen)}
        isOpen={statusModalIsOpen}
      />

      <ChangeAssignModal
        defaultStatus={feedbackData.status}
        assignedUserEmailDefault={feedbackData.assignedUserEmail}
        assignedUserFioDefault={feedbackData.assignedUserFio}
        assignedUserIdDefault={feedbackData.assignedUserId}
        feedbackId={params.feedbackId}
        onCancel={() => setAssignModalIsOpen(false)}
        onOk={onUpdateData(setAssignModalIsOpen)}
        isOpen={assignModalIsOpen}
      />

      <div className="feedback-page">
        <div className="feedback-page__button-wrapper">
          <ButtonLink url={paths.feedback} leftIcon={<ArrowBackIcon />} content="Все обращения" />
          {isReopened ? (
            <Button className="feedback-page__status-btn feedback-page__status-btn_reopened" type={ButtonType.outline}>
              Повторное
            </Button>
          ) : (
            <Button className="feedback-page__status-btn feedback-page__status-btn_initial" type={ButtonType.outline}>
              Первичное
            </Button>
          )}
        </div>
        {withoutSubscriber && (
          <div className="feedback-page__without-subscriber">
            <ExpandableBlock title="Детали обращения" wrapperClassName="feedback-page__info">
              <InformationBlock
                data={[
                  [
                    {
                      items: [
                        {
                          name: 'Объект',
                          value: feedbackData.objectName,
                        },
                        {
                          name: 'Адрес',
                          value: feedbackData.addressNotes,
                        },
                        {
                          name: 'Автор',
                          value: feedbackData.creator,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      items: [
                        {
                          name: 'Категория',
                          value: (
                            <EditableBlock
                              disabled={disableEdit || isReopened}
                              onClick={() => setCategoryModalIsOpen(true)}
                            >
                              {feedbackData.category}
                            </EditableBlock>
                          ),
                        },
                        {
                          name: 'Статус',
                          value: (
                            <EditableBlock disabled={disableEdit} onClick={() => setStatusModalIsOpen(true)}>
                              <FeedbackStatus
                                wrapClassName="feedback-page__status"
                                status={feedbackData?.status as EFeedbackStatus}
                              />
                            </EditableBlock>
                          ),
                        },
                        {
                          name: 'Приоритет',
                          value: (
                            <EditableBlock
                              disabled={disableEdit || isReopened}
                              onClick={() => setPriorityModalIsOpen(true)}
                            >
                              <FeedbacksPriority
                                wrapClassName="feedback-page__priority"
                                priority={feedbackData?.priority as EFeedbackPriority}
                              />
                            </EditableBlock>
                          ),
                        },
                      ],
                    },
                  ],
                  [
                    {
                      items: [
                        {
                          name: 'Дата создания',
                          value: feedbackData?.createTime
                            ? moment(feedbackData?.createTime).format(dateDefaultFormat)
                            : '-',
                        },
                        {
                          name: 'Дата изменения',
                          value: feedbackData?.updateTime
                            ? moment(feedbackData?.updateTime).format(dateDefaultFormat)
                            : '-',
                        },
                        {
                          name: (
                            <>
                              Примерная дата <br /> исполнения
                            </>
                          ),
                          value: feedbackData?.executionTime
                            ? moment(feedbackData?.executionTime).format(dateDefaultFormat)
                            : '-',
                        },
                      ],
                    },
                  ],
                ]}
              />
            </ExpandableBlock>
          </div>
        )}

        <div className="feedback-page__content">
          <div className="feedback-page__content-main">
            {!withoutSubscriber && (
              <ExpandableBlock title="Детали обращения" wrapperClassName="feedback-page__info">
                <InformationBlock
                  data={[
                    [
                      {
                        items: [
                          {
                            name: 'Категория',
                            value: (
                              <EditableBlock
                                disabled={disableEdit || isReopened}
                                onClick={() => setCategoryModalIsOpen(true)}
                              >
                                {feedbackData.category}
                              </EditableBlock>
                            ),
                          },
                          {
                            name: 'Статус',
                            value: (
                              <EditableBlock disabled={disableEdit} onClick={() => setStatusModalIsOpen(true)}>
                                <FeedbackStatus
                                  wrapClassName="feedback-page__status"
                                  status={feedbackData?.status as EFeedbackStatus}
                                />
                              </EditableBlock>
                            ),
                          },

                          {
                            name: 'Приоритет',
                            value: (
                              <EditableBlock
                                disabled={disableEdit || isReopened}
                                onClick={() => setPriorityModalIsOpen(true)}
                              >
                                <FeedbacksPriority
                                  wrapClassName="feedback-page__priority"
                                  priority={feedbackData?.priority as EFeedbackPriority}
                                />
                              </EditableBlock>
                            ),
                          },
                        ],
                      },
                    ],
                    [
                      {
                        items: [
                          {
                            name: 'Дата создания',
                            value: feedbackData?.createTime
                              ? moment(feedbackData?.createTime).format(dateDefaultFormat)
                              : '-',
                          },
                          {
                            name: 'Дата изменения',
                            value: feedbackData?.updateTime
                              ? moment(feedbackData?.updateTime).format(dateDefaultFormat)
                              : '-',
                          },
                          {
                            name: (
                              <>
                                Примерная дата <br /> исполнения
                              </>
                            ),
                            value: feedbackData?.executionTime
                              ? moment(feedbackData?.executionTime).format(dateDefaultFormat)
                              : '-',
                          },
                        ],
                      },
                    ],
                  ]}
                />
              </ExpandableBlock>
            )}

            <div className="feedback-page__main-info">
              <div className="feedback-page__title">{feedbackData.subject}</div>

              {isReopened ? (
                <Tabs
                  activeTabKey={activeTab}
                  onChangeActiveTab={setActiveTab}
                  tabsClassName="feedback-page__tabs"
                  tabs={feedbackData.parts
                    .slice()
                    .reverse()
                    .map<ITab>((part, index) => ({
                      id: part.id,
                      title:
                        index === feedbackData.parts.length - 1
                          ? 'Первичное'
                          : `Повторное от ${moment(part.createDate || '').format(dateFormatNoYear)}`,
                      position: index,
                      children: renderMainInfo(part),
                    }))}
                />
              ) : (
                renderMainInfo(feedbackData.parts[0])
              )}
            </div>
            <ExpandableBlock title="История операций" wrapperClassName="feedback-page__info">
              <FeedbacksHistory feedbackData={feedbackData} appealId={params.feedbackId} />
            </ExpandableBlock>
          </div>
          <div className="feedback-page__content-additional">
            {!withoutSubscriber && (
              <ExpandableBlock title="Абонент" wrapperClassName="feedback-page__info">
                <InformationBlock
                  data={[
                    [
                      {
                        items: [
                          {
                            name: 'ФИО',
                            value: (
                              <div
                                className={subscriberPermissions?.view ? 'feedback-page__user' : ''}
                                role="presentation"
                                onClick={() =>
                                  subscriberPermissions?.view &&
                                  navigate(`${paths.subscribers}/${feedbackData.subscriberId}`)
                                }
                              >
                                {`${feedbackData.subscriberMiddleName} ${feedbackData.subscriberFirstName} ${feedbackData.subscriberLastName}`}
                              </div>
                            ),
                          },
                          {
                            name: 'Номер телефона',
                            value: feedbackData.subscriberPhone || '-',
                          },
                          {
                            name: 'Email',
                            value: feedbackData.subscriberEmail || '-',
                          },
                          {
                            name: 'Адрес',
                            value: feedbackData.flatAddress || feedbackData.addressNotes || '-',
                          },
                          {
                            name: 'Объект',
                            value: feedbackData?.objectName || '-',
                          },
                          {
                            name: 'Оценка',
                            value: <FeedbacksScore score={feedbackData.score} />,
                          },
                        ],
                      },
                    ],
                  ]}
                />
              </ExpandableBlock>
            )}

            <ExpandableBlock title="Исполнитель" wrapperClassName="feedback-page__info">
              {feedbackData.assignedUserId ? (
                <InformationBlock
                  data={[
                    [
                      {
                        items: [
                          {
                            name: 'ФИО',
                            value: (
                              <EditableBlock disabled={disableEdit} onClick={() => setAssignModalIsOpen(true)}>
                                {feedbackData.assignedUserFio || '-'}
                              </EditableBlock>
                            ),
                          },
                          {
                            name: 'Номер телефона',
                            value: feedbackData.assignedUserPhone || '-',
                          },
                          {
                            name: 'Email',
                            value: feedbackData.assignedUserEmail || '-',
                          },
                        ],
                      },
                    ],
                  ]}
                />
              ) : (
                <Button
                  disabled={disableEdit}
                  loading={assignToMeLoading}
                  onClick={onAssignYourself}
                  type={ButtonType.outline}
                >
                  Назначить себя
                </Button>
              )}
            </ExpandableBlock>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default FeedbackPage;
